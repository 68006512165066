import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Banner from '../../../core/Banner';
import Panel from '../../../core/Panel';
import Heading from '../../../core/Heading';
import Columns from '../../../core/Columns';
import Column from '../../../core/Column';
import Image from '../../../core/Image';
import Paragraph from '../../../core/Paragraph';

import './index.css';

import creditCardProcessorImage from './images/payment-couple.jpg';
import heartlandImage from './images/heartland.svg';
import braintreeImage from './images/braintree.svg';
import authorizeNetImage from './images/authorize.svg';
import mpiImage from './images/MPI_logo.svg';
import stripeImage from './images/stripe_logo_color.svg';

export class PaymentProcessingPage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component payment-processing-page">
          <Helmet title="Payment Processing for Hotels & Lodging – ThinkReservations">
            <meta
              name="description"
              content="Integrating your online booking and hotel credit card processing with ThinkReservations saves you time and money. Click here to learn more."
            />
            <script
              type="application/ld+json"
              children={JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Service',
                serviceType: 'Payment Processing Solutions',
                category:
                  'Reservation Management Software for Small and Boutique Hotels',
                provider: {
                  '@type': 'Organization',
                  name: 'ThinkReservations',
                },
                areaServed: {
                  '@type': 'Country',
                  name: 'USA',
                },
              })}
            />
          </Helmet>

          <Banner
            header="Hotel Payment Processing"
            subheader="Simplify the way you process payments"
            content="ThinkReservations makes it simple to connect your hotel payment processing system. We support 
            many different integrations that keep you in control of how you want to process payments."
          />

          <Panel id="choose-cc-processor" backgroundColor="#f5f3f3">
            <Heading level={2} size="large">
              Choose which credit card processor to use
            </Heading>
            <Columns verticalAlign="top">
              <Column>
                <Paragraph>
                  <b>
                    ThinkReservations makes it simple to work with any credit
                    card processor.
                  </b>{' '}
                  We have direct connections with Heartland Payment Systems,
                  MPI, Stripe, and Braintree. Any other processors can be
                  connected through an Authorize.net account.
                </Paragraph>
                <br />
                <Paragraph>
                  Integrating your <a href="https://www.thinkreservations.com/hotels/" target="_blank">hotel</a> 
                  credit card processing with our platform improves the guest experience and gives your guests
                  another reason to book directly with you. By simplifying this
                  process with our help, you save time and money.
                </Paragraph>
              </Column>
              <Column>
                <Image src={creditCardProcessorImage} border={true} />
              </Column>
            </Columns>
            <br />
            <Paragraph>
              Our online booking and payment system enables you to provide 24/7
              booking, receive online payments securely and instantly, and helps
              you with cash flow. This powerful software solution with
              integrated <a href="https://www.thinkreservations.com/bed-and-breakfast/" target="_blank">hospitality</a> 
              credit card processing delivers benefits for
              virtually all types of lodging properties, including hotels,
              motels, bed & breakfasts, <a href="https://www.thinkreservations.com/vacation-rentals/" target="_blank">vacation rentals</a>
              , and more.
            </Paragraph>
            
          </Panel>

          <Panel id="credit-card-processors-we-support">
            <Columns>
              <Column>
                <Image
                  src={heartlandImage}
                  width="246"
                  alt="Heartland Payment Systems logo"
                />
              </Column>
              <Column>
                <Image src={braintreeImage} width="246" alt="Braintree logo" />
              </Column>
              <Column>
                <Image
                  src={authorizeNetImage}
                  width="246"
                  alt="Authorize.net logo"
                />
              </Column>
            </Columns>

            <Columns style={{ marginTop: '50px' }}>
              <Column>
                <Image src={mpiImage} width="150" alt="MPI logo" />
              </Column>
              <Column>
                <Image src={stripeImage} width="200" alt="Stripe logo" />
              </Column>
            </Columns>
          </Panel>
        </div>
      </App>
    );
  }
}

export default PaymentProcessingPage;
